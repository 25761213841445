export const blogConstant = {
  FETCH_BLOG_POST_REQUEST: 'FETCH_BLOG_POST_REQUEST',
  FETCH_BLOG_POST_SUCCESS: 'FETCH_BLOG_POST_SUCCESS',
  FETCH_BLOG_POST_FAILURE: 'FETCH_BLOG_POST_FAILURE',
  FETCH_BLOG_POST_IMAGE_REQUEST: 'FETCH_BLOG_POST_IMAGE_REQUEST',
  FETCH_BLOG_POST_IMAGE_SUCCESS: 'FETCH_BLOG_POST_IMAGE_SUCCESS',
  FETCH_BLOG_POST_IMAGE_FAILURE: 'FETCH_BLOG_POST_IMAGE_FAILURE',
  SEARCH_BLOG_REQUEST: 'SEARCH_BLOG_REQUEST',
  SEARCH_BLOG_SUCCESS: 'SEARCH_BLOG_SUCCESS',
  SEARCH_BLOG_FAILURE: 'SEARCH_BLOG_FAILURE',
};

export const postConstant = {
  FETCH_POST_REQUEST: 'FETCH_POST_REQUEST',
  FETCH_POST_SUCCESS: 'FETCH_POST_SUCCESS',
  FETCH_POST_FAILURE: 'FETCH_POST_FAILURE',
};

export const Authenticate = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
};

export const ActConstant = {
  AD_FETCH_POST_REQUEST: 'AD_FETCH_POST_REQUEST',
  AD_FETCH_POST_SUCCESS: 'AD_FETCH_POST_SUCCESS',
  AD_FETCH_POST_FAILURE: 'AD_FETCH_POST_FAILURE',
};

export const ActCategories = {
  AD_ADD_CATEGORY_REQUEST: 'AD_ADD_CATEGORY_REQUEST',
  AD_ADD_CATEGORY_SUCCESS: 'AD_ADD_CATEGORY_SUCCESS',
  AD_ADD_CATEGORY_FAILURE: 'AD_ADD_CATEGORY_FAILURE',
  AD_FETCH_CATEGORY_REQUEST: 'AD_FETCH_CATEGORY_REQUEST',
  AD_FETCH_CATEGORY_SUCCESS: 'AD_FETCH_CATEGORY_SUCCESS',
  AD_FETCH_CATEGORY_FAILURE: 'AD_FETCH_CATEGORY_FAILURE',
};
