import React, { useState, useEffect } from 'react';

function AdminContact(props) {
  const [state, setState] = useState('');

  useEffect(() => {
    return () => {};
  }, []);

  return <>ReactJS Starter Template by Varun Bardwaj</>;
}

export default AdminContact;
